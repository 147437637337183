import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  selectIsPaused,
  selectIsPlaying,
} from '../../../Audio/selectors/audioSelectors';
import Logger from '../../Logger';
import { StockItem, StockItemContext } from '../../types/StockItemTypes';

interface AudioPlayerTitleProps {
  title: string;
  detailsUrl: string;
  context?: string;
  isCurrentSource?: boolean;
  wrapperClassName?: string;
  className?: string;
  headerClassName?: string;
  openDetailsInNewWindow?: boolean;
  stockItem?: StockItem;
  stockItemCardContext?: { title: string; position: number };
}

const AudioPlayerTitle = ({
  title,
  detailsUrl,
  context,
  isCurrentSource,
  wrapperClassName,
  className,
  headerClassName,
  openDetailsInNewWindow,
  stockItem,
  stockItemCardContext,
}: AudioPlayerTitleProps): JSX.Element => {
  const isPlaying = useSelector(selectIsPlaying);
  const isPaused = useSelector(selectIsPaused);
  const getTitleWrapperClassNames = () => {
    return `${wrapperClassName} ${twMerge(
      'item-title',
      isCurrentSource && isPaused && 'paused',
      isCurrentSource && isPlaying && 'playing'
    )}`;
  };

  const handleStockItemClick = () => {
    Logger.produceDetailsClickEvent(
      stockItem,
      stockItemCardContext && stockItemCardContext.position
    );
  };

  return (
    <span className={`${getTitleWrapperClassNames()} w-full font-bold text-sm`}>
      {context === StockItemContext.DETAILS_PLAYER ? (
        <span>{title}</span>
      ) : (
        <h3 className={twMerge('text-sm my-0', headerClassName)}>
          <a
            className={`hover:underline ${className}`}
            href={detailsUrl}
            data-selenium-value={title}
            target={openDetailsInNewWindow ? '_blank' : '_self'}
            onClick={handleStockItemClick}
          >
            {title}
          </a>
        </h3>
      )}
    </span>
  );
};

export default AudioPlayerTitle;
